.result-container {
  width: 30%;
  height: 60vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-container h1 {
  color: greenyellow;
}

.result-container p {
  font-size: 25px;
  color: snow;
  font-weight: 700;
}
