* {
    list-style: none;
    box-sizing: border-box;
}


.quiz-container {
    display: flex;
    justify-content: center;
}


.quiz-container h1 {
    padding: 20px 5px;
    font-size: 24px;
}

.quiz-container .options-container {
    background-color: snow;
    padding-bottom: 1px;
    border-radius: 5px;
}


.options-container .list {
    background-color: aliceblue;
    padding: 0px 0px 0px 50px;
    display: flex;
    align-items: center;
}

.options-container .list > [type='checkbox'] {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.options-container .list label {
    width: 100%;
    font-size: 20px;
    padding: 15px 0px 15px 0px;

}

.options-container .list:hover {
    background-color: #DBDFFD;
}



.container .quiz-container .list-container {
    opacity: 75%;
}

.list-container .question-text {
    background-color: #293462;
    color: snow;
}