.login-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container h1 {
  color: snow;
}

.login-container .form-container {
  background-color: transparent;
  border: 1px solid snow;
}

.login-container .login-btn {
  background-color: transparent;
  border-radius: 20px;
  width: 50%;
  display: block;
  margin: 0 auto;
  color: snow;
  font-size: 18px;
  font-weight: 500;
}

.login-container .login-btn:hover {
  background-color: #198754;
}
