.pagination-container {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
}

.pagination-container .page-num {
    color: snow;
    font-size: 18px;
    font-weight: 800;
}

@media (max-width: 630px) {
    .pagination-container {
        flex-direction: column;
    }

    .pagination-container .page-num {
        margin: 5px 0px;
    }
}
@media (max-width: 700px) {
    .pagination-container {
        flex-direction: column;
    }

    .pagination-container .submit-btn {
        margin: 10px 0px;
    }
}